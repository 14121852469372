.flyover {
  margin-top: -1000px;
  overflow: hidden;
  position: fixed;
  width: 70%;
  opacity: 0.9;
  z-index: 1050;
  transition: all 1s ease;
}

.flyover.in {
  margin-top: 10px;
}

#alerter-container {
  position: fixed;
  top: 0;
  z-index: 99999;
}

#alerter-container h3 {
  margin: 0 0 10px 0;
}

.button-loading {
  display: inline-block;
  background-image: url(../images/button-loading.gif);
  height: 11px;
  width: 16px;
}
#cc-modal {
  z-index: 99999;
}
.modal-alert {
  width: 95%;
  margin: 0 auto;
}
#cc-modal-alert {
  margin-top: 10px;
}

ul.common-ancestor {
  list-style-type: none;
  padding-left: 0;
}
ul.common-ancestor li {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}
