body{
    font-family: "Nunito",sans-serif;
    margin:0 0 0 0;
    padding:0;
    font-size:12pt;
    color:#333;
    background-color: #aeafb4;
}

.divide-text{
    color:#fff;
    line-height: 20px;
    font-size:20px;
    padding: 15px 0;
}

.affix {
    top: 0px;
    width:100%;
}


.navbar-top{
    display: none;
}


.navbar-lower{
    margin-top: 0;
    background-color: #433a3c;
    border: 0;
    border-radius: 0;
    z-index: 9998;
    margin-bottom: 0;
}

.navbar-lower h3{
    margin: 5px 0 0 5px;
    padding: 0;
    color: #dfdfe7;
    font-family: "Nunito";
    font-size: 1.6em;
}

.navbar-lower li a{
    color: #aeafb4 !important;
}
.navbar-lower li a:focus, .navbar-lower li a:hover{
    background-color: #2e2829 !important;
}
.navbar-lower .dropdown-menu li a{
    color: #ffffff !important;
}

.main{
    background-color: #ffffff;
    min-height: 600px;
}

.footer{
    margin-top: 40px;
}
.footer img{
    display: block;
    margin: 0 auto ;
}

.row.main-content-pad{
    padding-top: 20px;
}

.navbar-toggle:hover, .navbar-toggle:focus{
    background-color: #000000 !important;
}

/* styles for the footer drop down menu (visible on < 768)
   ------------------------------------------------------- */
.small-footer-navbar  li a{
    color: #333333 !important;
}
.small-footer-navbar li a:focus, .small-footer-navbar li a:hover{
    background-color: #939498 !important;
}
.small-footer-navbar .dropdown-menu li a{
    color: #ffffff !important;
}

@media (min-width: 768px) {

    .small-footer-navbar{
        display: none;
    }

    .navbar-top{
        margin-bottom: 0 !important;
        background-color: #231f20;
        border: 0;
        border-radius: 0;
        z-index: 9999;
    }
    .navbar-top h3{
        margin: 0;
        padding: 0;
        color: #dfdfe7;
        font-family: "Nunito";
        font-size: 1.9em;
    }
    .navbar-top li a{
        color: #aeafb4 !important;
    }
    .navbar-top li a:focus{
        background-color: #aeafb4 !important;
    }
    .navbar-top .dropdown-menu li a{
        color: #444444 !important;
    }

    .navbar-top{
        display: block;
    }

    .navbar-top li a{
        color: #aeafb4 !important;
    }
    .navbar-top li a:focus{
        background-color: #000000 !important;
    }
    .navbar-top .dropdown-menu li a{
        color: #444444 !important;
    }

    .navbar-form {
        padding-left: 0;
    }

    .navbar-collapse{
        padding-left:0;
    }


    .navbar-lower li a{
        color: #aeafb4 !important;
    }
    .navbar-lower li a:focus{
        background-color: #2e2829 !important;
    }
    .navbar-lower .dropdown-menu li a{
        color: #444444 !important;
    }
    .navbar-lower .dropdown-menu li a:hover{
        color: #eeeeee !important;
    }
    .navbar-lower h3{
        display: none;
    }

    span.metis-logo{
        display: inline-block;
        /* background-image: url(../images/metis-logo.png);*/
        width: 50px;
        height: 35px;
        background-size:100% auto;
        background-position: bottom;
        background-repeat: no-repeat;
        margin: 0 10px 0 0;
    }
}

@import url('https://fonts.googleapis.com/css?family=Nunito');